import React from "react";
import Layout from "../components/UI/layout";
import Header from "../components/UI/Header/header";
import SEO from "../components/UI/seo";
import ProjectSection from "../components/Sure/ProjectsSure/ProjectsSure";
import ChallengeSection from "../components/Sure/challenge/challenge";
import Contact from "../components/Sure/Contact/contact";
import { graphql } from "gatsby"


const surePage = () => (
    <Layout>
      <SEO title="Codeicus | Sure" />
      <Header
        title="Hablamos el mismo código"
        description={["El éxito en el sector de seguros se da gracias a la innovación y el desarrollo tecnológico. Las compañías que apuestan a la tecnología para brindar a sus clientes una experiencia completa y única son las que resaltan en un mercado cada vez más competitivo.<br></br> Hablamos tu mismo lenguaje y tenemos lo que necesitas para que tu compañía sea líder en el sector."]}/>
      <ProjectSection/>
      <ChallengeSection/>
      <Contact />
    </Layout>
  )
  
  export default surePage

  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;