import React from 'react';
import * as styles from './card.module.scss';


const Card = ({img, title}) => {

	return (
        <div data-sal-delay='300'
             data-sal='zoom-in'
             data-sal-duration='700'
             data-sal-easing='ease-out-back'
             className={styles.contCard}> 
            <div className={styles.contDescriptionCard}>
                <div className={styles.contImg}>
                    <img src={img} alt='imagen de referencia' />
                </div>
                <div className={styles.contDescription}>
                    <h3>{title}</h3>
                </div>
            </div>
        </div>
	);
};

export default Card;