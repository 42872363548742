import React from 'react';
import * as styles from './challenge.module.scss';
import Button from '../../Sure/Button/button';
import { useTranslation } from "gatsby-plugin-react-i18next"



const ChallengeSection = () => {


    const {t} = useTranslation();

	return (
		<section className={styles.challengeSection} id='challengeSection'>
			<div className={styles.containProject}>
                <div className={styles.containTitle}    
                    data-sal-delay='400'
                    data-sal='slide-up'
                    data-sal-duration='400'
                    data-sal-easing='ease-out-back'>
                    <h3>{t("surePage.challengeSection.title.1")}<br></br>{t("surePage.challengeSection.title.2")}</h3>
                    
                </div>
                <div className={styles.containBody}>
                    <div className={styles.containTitle}
                       data-sal-delay='400'
                       data-sal='slide-up'
                       data-sal-duration='400'
                       data-sal-easing='ease-out-back'>
                        <p>{t("surePage.challengeSection.body.p1")}</p>
                        <p>{t("surePage.challengeSection.body.p2")}</p>
                        <p></p>
                    </div>
                    <div className={styles.containBody}>                   
                    <div className={styles.step}
                        data-sal-delay='200'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>1</p>
                            </div>
                            <Button className={styles.title} linkTo='/challenges/challengeOne' isLink >     
                                <h3>{t("surePage.challengeSection.challenge.1")}</h3> 
                            </Button>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>2</p>
                            </div>
                            <Button className={styles.title} linkTo='/challenges/challengeTwo' isLink >     
                                <h3>{t("surePage.challengeSection.challenge.2")}</h3> 
                            </Button>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>3</p>
                            </div>
                            <Button className={styles.title} linkTo='/challenges/challengeThree' isLink > 
                                <h3>{t("surePage.challengeSection.challenge.3")}</h3> 
                            </Button>
                        </div>
                    </div>       
                </div>
                </div>

			</div>
		</section>
	);
};
export default ChallengeSection ;