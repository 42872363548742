import React from 'react';
import * as styles from './ProjectsSure.module.scss';
import Card from '../../Sure/Card/card.js';
import Button from '../../UI/Button/button';

import firmaDigital from '../../../images/Sure/firmaDigital.png';
import cotizadorAsinc from '../../../images/Sure/cotizadorAsinc.png'
import mvp from '../../../images/Sure/Mvp.png';
import { useTranslation } from "gatsby-plugin-react-i18next"


const ProjectSection = () => {

    const {t} = useTranslation();

	return (
        <section className={styles.projectSection} id='projectSection'>
        <div className={styles.containProject}>
            <div className={styles.containTitle}    
                data-sal-delay='400'
                data-sal='slide-up'
                data-sal-duration='400'
                data-sal-easing='ease-out-back'>
                <h3>{t("surePage.projectSection.title")}</h3>
            </div>
            <div className={styles.containBody}>
                <div className={styles.containCars}>
                    <Button className={styles.Button} linkTo='/projects/firmadigital' isLink >                        
                        <Card
                            img = {firmaDigital}
                            title={t("surePage.projectSection.card.title.1")}
                            color='#a9b6eb'
                        />                    
                    </Button>
                    <Button className={styles.Button} linkTo='/projects/cotizacionAsincronica' isLink >  
                        <Card 
                            img = {cotizadorAsinc}
                            title={t("surePage.projectSection.card.title.2")}
                            color='#f081c6'
                         />     
                    </Button>
                    <Button className={styles.Button} linkTo='/projects/desarrolloMvp' isLink >  
                        <Card 
                            img = {mvp}
                            title={t("surePage.projectSection.card.title.3")}
                            color='#99d590'
                        />     
                    </Button>
                </div>
            </div>
        </div>
    </section>
	);
};
export default ProjectSection;
