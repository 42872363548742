// extracted by mini-css-extract-plugin
export var challengeSection = "challenge-module--challengeSection--cb527";
export var containBody = "challenge-module--containBody--cc812";
export var containCars = "challenge-module--containCars--c6168";
export var containProject = "challenge-module--containProject--be7dd";
export var containTitle = "challenge-module--containTitle--45176";
export var description = "challenge-module--description--5b7ec";
export var detalle = "challenge-module--detalle--65eb9";
export var number = "challenge-module--number--8d574";
export var step = "challenge-module--step--2e5fd";
export var title = "challenge-module--title--3e5d0";